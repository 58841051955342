import React from "react"
import {graphql, Link} from "gatsby"
import Seo from "../components/seo";
import Layout from "../components/layout";
import Sidebar from "../components/sidebar";
import * as styles from "./category.module.css"

const WpCategory = ({data}) => {
    const {
        wpCategory: {name, id, posts},
    } = data

    return (
        <Layout>
            <Seo title={name}/>
            <div className="flex flex-wrap">
                <div className="w-full md:w-9/12 px-8">
                    <div className={`category-${id}`}>
                        <h2 className="item-title">{name}</h2>
                    </div>
                    {posts.nodes.map(item =>
                        <div className="items pb-6 md:w-6/12 md:inline-block pl-2 align-top" key={item.id}>
                            <div className={`category-${item.id} ${styles.itemTitle} my-4`}>
                                <Link to={`/${item.slug}`}>
                                    {item.title}
                                </Link>
                            </div>
                            <div className="itemDescription">
                                <div dangerouslySetInnerHTML={{__html: item.excerpt}}/>
                            </div>
                        </div>
                    )}
                </div>
                <div className="w-full md:w-3/12">
                    <Sidebar title={name} items={posts.nodes}/>
                </div>
            </div>
        </Layout>
    )
}

export const query = graphql`
  query ($id: String) {
    wpCategory(id: { eq: $id }) {
      id
      name
      posts {
        nodes {
          id
          title
          slug
          excerpt
        }
      }
    }
  }
`

export default WpCategory
